<template>
    <div>
        <div class="page-title flex flex-space-between">
            <span>展品列表</span>
            <div class="tools">
                <button @click="gotoUploadPage" class="btn">上传展品</button>
                <div>上传商品仅用于方便用户扫码添加到在线衣橱，不做销售使用</div>
            </div>
        </div>
        
        
        <div v-if="listLoading" class="loading-block">
            <loader/>
        </div>
        <div v-else-if="error">
            <a-alert type="error" :message="error" banner/>
        </div>
        <div v-else class="goods-list-container">
            
            <div class="goods-list">
                
                <template v-for="(item,ik) in list">
                    
                    <template v-if="!item.colors || !item.colors.length">
                        
                        <div :key="ik" class="goods-item">
                            
                            
                            <div class="goods-thumb">
                                <img :src="item.white_img || item.thumb_image" alt="">
                            </div>
                            <div class="goods-content">
                                <div class="goods-name">{{ item.product_name }}</div>
                                <div class="goods-attr">
                                    <div class="goods-price"><small>￥</small>{{ (item.price / 100.0).toFixed(2) }}
                                    </div>
                                    <div class="goods-stock">
                                        <template v-if="item.sku_num">
                                            库存 {{ item.sku_num }}
                                        </template>
                                    </div>
                                </div>
                            </div>
                        
                        
                        </div>
                    
                    </template>
                    
                    <template v-else>
                        
                        <template v-for="color of item.colors">
                            
                            <div :key="`c${color.id}`" class="goods-item">
                                
                                
                                <div class="goods-thumb">
                                    <img :src="color.white_image || color.thumb" alt="">
                                </div>
                                <div class="goods-content">
                                    <div class="goods-name">{{ item.product_name }} - {{ color.color }}</div>
                                    <div class="goods-attr">
                                        <div class="goods-price">
                                            <small>￥</small>{{ item.price }}
                                        </div>
                                        <div class="goods-stock">
                                            <template v-if="item.sku_num">
                                                库存 {{ item.color_stocks[color.color] }}
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            
                            
                            </div>
                        
                        </template>
                    
                    </template>
                
                </template>
                
                <div class="goods-item"></div>
                <div class="goods-item"></div>
                <div class="goods-item"></div>
                <div class="goods-item"></div>
                <div class="goods-item"></div>
            </div>
            
            <div>
                <a-pagination
                    class="st-pagination"
                    v-if="pager.total > 0 "
                    :current="pager.current"
                    :total="pager.total"
                    :page-size="pager.size"
                    :show-total="(total, range) => `${range[0]}-${range[1]},共${total}条数据`"
                    @change="gotoPage"
                />
            </div>
        </div>
    
    </div>
</template>

<script>
import Loader from "../../../components/Loader";
import api from "../../../repo/api";

export default {
    name: "ExhibitList",
    components: {Loader},
    data() {
        return {
            listLoading: true,
            error: '',
            query: {
                page: 1
            },
            pager: {
                defaultCurrent: 1,
                current: 1,
                defaultPageSize: 10,
                total: 1,
                pageSize: 15,
            },
            list: []
        }
    },
    mounted() {
        this.getFirstPage()
    },
    methods: {
        gotoUploadPage() {
            this.$router.push('/ast/shop/exhibits/upload')
        },
        gotoPage(page) {
            this.query.page = page
            this.getList()
        },
        getFirstPage() {
            this.gotoPage(1)
        },
        getList() {
            this.listLoading = true
            
            api.get('/ast-app/exhibit-goods', this.query, (data) => {
                
                this.listLoading = false
                
                if (data.code !== 0) {
                    this.error = data.msg
                    return
                }
                
                this.list = data.data.data
                this.pager.current = data.data.current_page
                this.pager.total = data.data.total
                this.pager.size = data.data.per_page
                
            })
        }
    }
}
</script>

<style lang="less" scoped>

.goods-list {
    display: flex;
    flex-wrap: wrap;
}

.goods-name {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
    margin-bottom: 11px;
}

.goods-item {
    width: 232px;
    margin-right: 24px;
    margin-bottom: 32px;
}

.goods-attr {
    display: flex;
    justify-content: space-between;
}

.goods-thumb {
    width: 232px;
    height: 232px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F5F5F5;
    padding: 16px;
    margin-bottom: 16px;
    
    
    img {
        max-width: 100%;
        max-height: 100%;
    }
}

.goods-price {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.8);
    line-height: 12px;
}

.goods-stock {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
    line-height: 17px;
    font-size: 12px;
}

.page-title {
    align-items: flex-start;
    
    .tools {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        
        font-size: 12px;
        
        .btn {
            width: 100px;
        }
    }
}
</style>